import { useEffect, useState } from "react";

import "./CommentsAdmin.css";

import { RegisterCommentsObserver } from 'services/Firebase.js';

export default function CommentsAdmin(){

    const [comments, setComments] = useState([]);
    const commentsCache = [];

    function AddComment(comment){
        console.log(comment); 
        commentsCache.push(comment);  
        setComments([...commentsCache]);
    }

    useEffect(() => {
        RegisterCommentsObserver(AddComment);
    }, []);

    return (
        <div className="CommentsAdmin">
            <p className="title">Comentários</p>
            <div className="comments">
                {comments.map((commentElement, index) => {
                    return (
                        <div className="comment" key={index}>
                            <p className="commentName">{commentElement.name}</p>
                            <p className="commentText">{commentElement.comment}</p>
                            <p className="commentState">{commentElement.state}</p>
                            <p className="commentTime">{commentElement.time}</p>
                            <p className="commentIndex">{index + 1}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}