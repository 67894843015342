const RouteData = {
    path: 'home',
    yesNoEnabled: false,
    yesNoAnswered: false,
    surveyEnabled: false,
    commentsEnabled: false,
    resetSession: false,
    cacheWordCloud: [],
};

const RouteObservers = {
    path: [],
    yesNoEnabled: [],
    yesNoAnswered: [],
    surveyEnabled: [],
    commentsEnabled: [],
    resetSession: [],
    cacheWordCloud: [],
};

export function setRouteData(key, data) {
    RouteData[key] = data;
    notifyRouteObservers(key);
}

export function getRouteData(key) {
    return RouteData[key];
}

export function RegisterRouteObserver(observer, key) {
    RouteObservers[key].push(observer);
}

export function UnregisterRouteObserver(observer, key) {
    RouteObservers[key] = RouteObservers[key].filter((o) => o !== observer);
}

export function notifyRouteObservers(key) {
    RouteObservers[key].forEach((observer) => observer());
}
