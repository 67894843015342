
import { Fragment, useState, useEffect } from 'react';

import Home from 'components/Home';
import Emoji from 'components/Emoji';
import YesNo from 'components/YesNo';
import WordCloud from 'components/WordCloud';
import Survey from 'components/Survey';
import Comments from 'components/Comments';
import Thanks from 'components/Thanks';
import Config from 'components/Config';
import CommentsAdmin from 'components/CommentsAdmin';

import { RegisterRouteObserver, UnregisterRouteObserver, getRouteData } from 'interfaces/RouteInterface';

export default function App() {

    const [page, setPage] = useState('CommentsAdmin');


    function HandleRouteChange() {
        // const path = getRouteData('path');
        // setPage(path);
    }

    function HandleSessionChange() {        
        // if(page !== 'home' && page !== 'emoji' && page !== 'survey') {
        //     setPage('home');
        // }
    }

    useEffect(() => {
        // RegisterRouteObserver(HandleRouteChange, 'path');
        // RegisterRouteObserver(HandleSessionChange, 'resetSession');
        return () => {
            // UnregisterRouteObserver(HandleRouteChange, 'path');
            // UnregisterRouteObserver(HandleSessionChange, 'resetSession');
        };
    }, []);

    return (
        <Fragment>    
            <CommentsAdmin />        
            {/* {page === 'home' && <Home setPage={setPage} />}
            {page === 'emoji' && <Emoji setPage={setPage} />}
            {page === 'yesno' && <YesNo setPage={setPage} />}
            {page === 'wordcloud' && <WordCloud setPage={setPage} />}
            {page === 'survey' && <Survey setPage={setPage} />}
            {page === 'comments' && <Comments setPage={setPage} />}
            {page === 'thanks' && <Thanks setPage={setPage} />}
            {page === 'config' && <Config setPage={setPage} />}
            {page === 'commentsAdmin' && <CommentsAdmin setPage={setPage} />} */}
        </Fragment>
    );
}
