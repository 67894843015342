import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth,setPersistence, browserSessionPersistence  } from "firebase/auth";
import { getDatabase, ref, onChildChanged, set, runTransaction, update, onValue, push, get, onChildAdded  } from "firebase/database";

import { setRouteData, notifyRouteObservers } from 'interfaces/RouteInterface.js';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
  
const FirebaseData = {
    app: null,
    analytics: null,
    auth: null,
    database: null,
    user: null,
    session: 0,
    yesNoSession: 0,
    yesNoSessionEnabled: false,
    surveySession: 0,
    surveySessionEnabled: false,
};

const CommentsObserver = [];

export function getFirebaseData(key) {
    return FirebaseData[key];
}

export function InitializeFirebase(){
    FirebaseData['app'] = initializeApp(firebaseConfig);
    FirebaseData['analytics'] = getAnalytics(FirebaseData['app']);
    FirebaseData['auth'] = getAuth();
    FirebaseData['database'] = getDatabase(FirebaseData['app']);    

    setPersistence(FirebaseData['auth'], browserSessionPersistence)
    .then(() => {
        // console.log("Firebase: Persistence set to session");
    });


    onValue(ref(FirebaseData['database'], 'rt/config/activeSession'), (snapshot) => {
        // console.log("activeSession");
        // console.log(snapshot.val());
        FirebaseData['session'] = snapshot.val();
        notifyRouteObservers('resetSession');
    }, {    
        onlyOnce: false
    });

    onValue(ref(FirebaseData['database'], 'rt/config/YesNoSession'), (snapshot) => {
        // console.log("YesNoSession");
        // console.log(snapshot.val());
        FirebaseData['yesNoSession'] = snapshot.val();
    }
    , {
        onlyOnce: false
    });
    
    onValue(ref(FirebaseData['database'], 'rt/config/YesNoSessionEnabled'), (snapshot) => {
        // console.log("YesNoSessionEnabled");
        // console.log(snapshot.val());
        FirebaseData['yesNoSessionEnabled'] = snapshot.val();
        setRouteData('yesNoEnabled', snapshot.val());
        if(snapshot.val()) {
            setRouteData('yesNoAnswered', false);
        }
    }
    , {
        onlyOnce: false
    });

    onValue(ref(FirebaseData['database'], 'rt/config/SurveySession'), (snapshot) => {
        // console.log("SurveySession");
        // console.log(snapshot.val());
        FirebaseData['surveySession'] = snapshot.val();
    }
    , {
        onlyOnce: false
    });

    onValue(ref(FirebaseData['database'], 'rt/config/SurveySessionEnabled'), (snapshot) => {
        // console.log("SurveySessionEnabled");
        // console.log(snapshot.val());
        FirebaseData['surveySessionEnabled'] = snapshot.val();
        setRouteData('surveyEnabled', snapshot.val());
    }
    , {
        onlyOnce: false
    });

    setTimeout(() => {

        onChildAdded(ref(FirebaseData['database'], 'rt/comments'), (snapshot) => {
            // console.log("Comments");
            console.log(snapshot.val());
            CommentsObserver.forEach(callback => {
                callback(snapshot.val());
            });
        });
    }, 1000);

}

export function RegisterCommentsObserver(callback) {
    CommentsObserver.push(callback);
}

export function SendEmoji(emoji) {
    const emojiRef = ref(FirebaseData['database'], 'rt/emojis/' + emoji);
    runTransaction(emojiRef, (count) => { 
        count++;        
        return count;
    });
}

export function SendWordCloud(word) {
    const wordRef = ref(FirebaseData['database'], 'rt/wordcloud/' + word);
    runTransaction(wordRef, (count) => { 
        count++;        
        return count;
    });
}

export function RemoveWordCloud(word) {
    const wordRef = ref(FirebaseData['database'], 'rt/wordcloud/' + word);
    runTransaction(wordRef, (count) => { 
        count--;        
        return count;
    });
}

export function SendYesNo(answer) {
    const yesnoRef = ref(FirebaseData['database'], 'rt/yesno/' + answer);
    runTransaction(yesnoRef, (count) => { 
        count++;        
        return count;
    });
}

export function SendComment(formData) {
    const commentRef = ref(FirebaseData['database'], 'rt/comments/');
    push(commentRef, formData);
}

export function SendSurvey(selected) {
    const commentRef = ref(FirebaseData['database'], 'rt/survey/');
    selected.forEach(element => {
        runTransaction(ref(FirebaseData['database'], 'rt/survey/option'+ element), (count) => { 
            count++;        
            return count;
        });  
    });
}

export function SetNewSession() {
    get(ref(FirebaseData['database'], `rt/emojis`))
    .then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val());
            set(ref(FirebaseData['database'], 'result/emojis_' + FirebaseData['session']),
                    snapshot.val()
            )
            .then(() => {
                set(ref(FirebaseData['database'], 'rt/emojis'),
                {
                    agree: 0,
                    congratulations: 0,
                    like: 0,
                    love: 0,
                    surprising: 0,
                    think: 0,
                    uau: 0
                })
                .then(() => {
                    get(ref(FirebaseData['database'], `rt/wordcloud`))
                    .then((snapshot2) => {
                        if (snapshot2.exists()) {
                            console.log(snapshot2.val());
                            set(ref(FirebaseData['database'], 'result/wordcloud_' + FirebaseData['session']),
                                    snapshot2.val()
                            )
                            .then(() => {
                                set(ref(FirebaseData['database'], 'rt/wordcloud'),
                                {
                                    wc1: 0,
                                    wc2: 0,
                                    wc3: 0,
                                    wc4: 0,
                                    wc5: 0,
                                    wc6: 0,
                                    wc7: 0,
                                    wc8: 0,
                                    wc9: 0,
                                    wc10: 0,
                                    wc11: 0,
                                    wc12: 0,
                                    wc13: 0,
                                    wc14: 0,
                                    wc15: 0,
                                    wc16: 0,
                                    wc17: 0,
                                    wc18: 0,
                                    wc19: 0,
                                    wc20: 0
                                })
                                .then(() => {
                                    runTransaction(ref(FirebaseData['database'], 'rt/config/activeSession'), (count) => { 
                                        count++;        
                                        return count;
                                    });
                                }).catch((error) => {
                                    console.error(error);
                                });
                            }).catch((error) => {
                                console.error(error);
                            });
                        }
                    }).catch((error) => {
                        console.error(error);
                    }); 
                }).catch((error) => {
                    console.error(error);
                });
            }).catch((error) => {
                console.error(error);
            });
        } else {
            console.log("No data available");
        }
    }).catch((error) => {
        console.error(error);
    });
}

export function SetNewYesNoSession() {
    if(FirebaseData['yesNoSessionEnabled']) {
        get(ref(FirebaseData['database'], `rt/yesno`))
        .then((snapshot) => {
            if (snapshot.exists()) {                
                console.log(snapshot.val());
                set(ref(FirebaseData['database'], 'result/yesno_'+FirebaseData['yesNoSession']), 
                        snapshot.val()                
                )
                .then(() => {
                    set(ref(FirebaseData['database'], 'rt/yesno'), 
                    {
                        yes: 0,
                        no: 0,
                        maybe: 0

                    })
                    .then(() => {
                        update(ref(FirebaseData['database'], 'rt/config'), {
                            YesNoSessionEnabled: FirebaseData['yesNoSessionEnabled'] ? false : true
                        });
                    });
                }).catch((error) => {
                    console.error(error);
            });
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
                console.error(error);
        });
        
    }else{
        runTransaction(ref(FirebaseData['database'], 'rt/config/YesNoSession'), (count) => { 
            count++;        
            return count;
        });
        update(ref(FirebaseData['database'], 'rt/config'), {
            YesNoSessionEnabled: FirebaseData['yesNoSessionEnabled'] ? false : true
        });
    }
}

export function SetNewSurveySession() {
    if(FirebaseData['surveySessionEnabled']){
        get(ref(FirebaseData['database'], `rt/survey`))
        .then((snapshot) => {
            if (snapshot.exists()) {
                console.log(snapshot.val());
                set(ref(FirebaseData['database'], 'result/survey_'+FirebaseData['surveySession']),
                        snapshot.val()
                )
                .then(() => {
                    set(ref(FirebaseData['database'], 'rt/survey'),
                    {
                        option1: 0,
                        option2: 0,
                        option3: 0,
                        option4: 0,
                        option5: 0,
                        option6: 0,
                        option7: 0,
                        option8: 0,
                        option9: 0,
                        option10: 0,
                        option11: 0,
                        option12: 0,
                        option13: 0,
                        option14: 0,
                        option15: 0,
                        option16: 0,
                        option17: 0,
                        option18: 0,
                        option19: 0,
                        option20: 0
                    }                    
                    )
                    .then(() => {
                        update(ref(FirebaseData['database'], 'rt/config'), {
                            SurveySessionEnabled: FirebaseData['surveySessionEnabled'] ? false : true
                        });
                    }).catch((error) => {
                        console.error(error);
                    });                    
                }).catch((error) => {
                    console.error(error);
                });
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }else{
        runTransaction(ref(FirebaseData['database'], 'rt/config/SurveySession'), (count) => { 
            count++;        
            return count;
        });
        update(ref(FirebaseData['database'], 'rt/config'), {
            SurveySessionEnabled: FirebaseData['surveySessionEnabled'] ? false : true
        });
    }
}
