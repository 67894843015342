import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App.js';

import { InitializeFirebase } from 'services/Firebase.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

InitializeFirebase();

root.render(
  <div className='bg'>
    <App />
  </div>
);

